<template>
  <div class="DetailContent">
    <div class="about" v-if="ArticleData.status == 1 && joinStudio">
      <el-button size="mini" type="primary" @click="PassWay(2)">审核通过</el-button>
      <el-button size="mini" type="danger" @click="noPass">审核不通过</el-button>
    </div>
    <div class="wzBox">
      <h4>{{ ArticleData.title }}</h4>
      <div class="mb20 wb">
        <span class="mr50">发布者：{{ ArticleData.username }}</span>
        <span class="mr50">发布时间：{{ ArticleData.create_time }}</span>
        <span class="mr50">浏览：{{ ArticleData.view_count }}</span>
        <span class="mr50">评论：{{ ArticleData.reply_count }}</span>
        <p class="inf_item_item" style="color: rgb(240, 95, 95);">
          {{ ArticleData.status == 1 ? '待审核' : ArticleData.status == 4 ? '未通过' : '' }}
          <el-popover v-if="ArticleData.status == 4" placement="top-start" title="" width="200" trigger="hover"
            :content="ArticleData.reason">
            <i style="color: rgb(240, 95, 95);" slot="reference" class="el-icon-question"></i>
          </el-popover>
        </p>
      </div>
      <div class="df mt20 mb20">
        <p style="color: #476dea;">【开展日期】{{ ArticleData.start_date }} <span v-if="ArticleData.type == 42">（为期{{
          ArticleData.days }}天）</span></p>
        <p style="color: #476dea;" class="ml30">【活动级别】{{ changeLever(ArticleData.report_level) }}</p>
      </div>
      <!-- 集中研修/听课磨课      罗海洋      发布时间：2023/10/12      浏览：9999       评论：9999 -->
      <!-- 文章内容 -->
      <div class="typeface rich_text_div_img" v-html="ArticleData.content"></div>

    </div>

    <!-- //不通过弹框 -->
    <el-dialog title="不通过的理由" :visible.sync="dialogVisible" width="30%">
      <el-input type="textarea" :rows="5" placeholder="请输入审核不通过的理由" resize="none" v-model="form1.reason">
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="PassWay(4)">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialogVisible: false,
      form1: {
        status: "", //2 审核通过 3审核不通过
        teaching_studio_id: localStorage.getItem("studioId"),
        id: "", //审核id
        reason: "",
      },
      joinStudio: false,
      ArticleData: "",
      list: {
        reply_id: "", //文章，回复或评论的id
        form: 1, //	1为文章 2为评论或回复
      },
      imgList: [
        {
          img1: require("@/assets/images/teacherStudio/wx_over.png"),
          img2: require("@/assets/images/teacherStudio/wx.png"),
          i: 0,
        },
        {
          img1: require("@/assets/images/teacherStudio/qq_over.png"),
          img2: require("@/assets/images/teacherStudio/qq.png"),
          i: 0,
        },
        {
          img1: require("@/assets/images/teacherStudio/xl_over.png"),
          img2: require("@/assets/images/teacherStudio/xl.png"),
          i: 0,
        },
        {
          img1: require("@/assets/images/teacherStudio/zone_over.png"),
          img2: require("@/assets/images/teacherStudio/zone.png"),
          i: 0,
        },
      ],
      current: 0,
    };
  },
  created() {
    //初始化
    this.joinStudio = JSON.parse(localStorage.getItem("joinStudio"))
    this.init();
  },
  computed: {},
  methods: {
    changeLever(val) {
      if (val == 1) return "国家级"
      if (val == 2) return "省级"
      if (val == 3) return "市级"
      if (val == 4) return "区县级"
      if (val == 5) return "校级"
      if (val == 6) return "其他"
    },
    //不通过
    noPass() {
      this.form1.id = this.$route.query.tid; //审核id
      this.dialogVisible = true;
    },
    //通过
    PassWay(type) {
      this.form1.status = type; //2 审核通过 3审核不通过
      this.form1.id = this.$route.query.tid; //审核id
      this.$axios
        .put("teachingStudioReport/status", this.form1)
        .then(() => {
          if (type == 2) {
            this.$message.success("审核通过");
          } else {
            this.$message.success("审核不通过");
            this.dialogVisible = false;
          }
          this.$router.go(0)
        });
    },
    init() {
      //  初始化文章详情
      this.TxtDetail();
    },
    //初始化文章详情
    TxtDetail() {
      let id = this.$route.query.tid;
      this.$axios
        .get("teachingStudioReport/details", {
          params: {
            id,
          },
        })
        .then((res) => {
          this.ArticleData = res.data.data;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.DetailContent {
  background: #fff;
  box-sizing: border-box;
  position: relative;

  .about {
    position: absolute;
    right: -27px;
    top: -37px;
    background: #f00;
    padding: 10px;
    background: rgba(234, 241, 247, 1);
    border-radius: 0 8px 0 8px;
  }

  .wzBox {
    h4 {
      width: 959px;
      font-size: 24px;
      font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
      font-weight: 700;
      text-align: center;
      color: #333333;
      margin-bottom: 15px;
    }

    .wb {
      font-size: 16px;
      color: #333333;
      display: flex;
      align-items: center;
      border-bottom: 1px dashed #f7f7f7;
      padding-bottom: 20px;
      justify-content: center;

      span {
        color: #666;
      }
    }

    .typeface {
      font-size: 16px;
      text-align: left;
      color: #333333;
      padding-bottom: 32px;
    }

    .shareBox {
      display: flex;
      justify-content: space-between;

      .leftMain {
        p {
          width: 32px;
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
          font-weight: 400;
          text-align: left;
          color: #9a9ea5;
        }

        .imgBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      //右边点赞
      .rightMain {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        div {
          display: flex;
          align-items: center;
          margin-left: 40px;
          font-size: 14px;
          font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
          font-weight: 400;
          text-align: left;
          color: #9a9ea5;

          img {
            width: 13px;
            height: 14px;
            background: #9a9ea5;
          }
        }
      }
    }

    .uu {
      padding-bottom: 30px;

      li {
        margin-right: 18px;
      }
    }
  }
}
</style>
