<template>
  <div class="ReportDetail public_main_bgc">
    <!-- 文章详情 -->
    <div class="ArrBox w_d">
      <!-- 导航 -->
      <div class="title">
        <p>
          当前位置：四名工作室 >
          <span @click="toList()" class="onL">活动报道</span> >
          <span>活动报道详情</span>
        </p>
      </div>
      <div class="fs discuss">
        <div class="mr20 bgc_f left">
          <titleCom :total="total" ref="titleCom" />
          <p class="send" v-if="isToken">发表评论</p>
          <!-- 发布盒子 -->
          <div class="pushBox" v-if="isToken">
            <div class="outBox">
              <el-input type="textarea" :rows="6" resize="none" placeholder="写下你的评论.." v-model="form.content"
                class="inputText">
              </el-input>
              <div class="addOneData cursor" @click="addComment()">发布</div>
            </div>
          </div>
          <new-comment :activity_id="form.teaching_studio_report_id" :module_type="9" ref="commentRef"
            @onChangeTotal="onChangeTotal"></new-comment>
        </div>

        <div class="mainRight">
          <!-- 名称 -->
          <div class="titleBox df dfb">
            <div class="titleLeft">
              <p>热点活动</p>
            </div>
          </div>
          <div class="mainBox">
            <div class="ulBox">
              <template v-if="recommanderList.length">
                <div class="liBox cp" v-for="(item, index) in recommanderList" :key="index" @click="gother(item.id)">
                  <h4 class="ellipsis-1 mb12">
                    <img class="width16 h-22" src="@/assets/banner_img/hot_icon.png" alt="" />
                    {{ item.title }}
                  </h4>
                  <div class="rightTop df dfb">
                    <div class="imgBox">
                      <img class="imgbig" v-if="item.cover_url" :src="item.cover_url" alt="" />
                      <img v-else class="imgbig" src="@/assets/images/banner/home_news_pic.png" alt="" />
                    </div>
                    <div class="txtbox">
                      <p class="ellipsis-3">{{ item.abstract }}</p>
                    </div>
                  </div>
                </div>
              </template>
              <el-empty :image-size="50" v-else description="暂无数据"></el-empty>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleCom from "./components/DetailContent.vue";
export default {
  data() {
    return {
      isToken: false,
      total: 0,
      color: {
        0: "#FA8C15",
        1: "#3489FF",
        2: "#00C1AE",
      },
      form: {
        teaching_studio_report_id: "",
        user_id: localStorage.getItem("id"),
        content: "",
      },
      recommanderList: [],
    };
  },
  components: {
    titleCom,
  },
  methods: {
    gother(id) {
      this.$router.push({
        path: "/ReportDetail",
        query: { tid: id },
      });
      this.$set(this.form, 'teaching_studio_report_id', this.$route.query.tid)
      this.$set(this.$refs.commentRef, 'activity_id', this.$route.query.tid)
      this.$refs.titleCom.init();
      this.$refs.commentRef.getCommentList();
    },
    //跳转详情
    toList() {
      this.$router.push("/ReportIndex");
    },

    onChangeTotal(total) {
      this.total = total;
    },
    //添加评论
    async addComment() {
      let res = await this.$axios.post(
        "teachingStudioReportReply/insert_comment",
        this.form
      );
      if (res.status == 200) {
        this.form.content = "";
        this.$message.success("评论成功");
        this.$refs.commentRef.getCommentList();
      }
    },
  },
  created() {
    this.isToken = localStorage.getItem("token") == null ? false : true;

    this.form.teaching_studio_report_id = this.$route.query.tid;
    const teaching_studio_id = this.$route.query.studioId
      ? this.$route.query.studioId
      : localStorage.getItem("studioId");
    // 四名推荐文章
    this.$axios
      .get("TeachingStudioReport/hot_listTeachingStudioReport", {
        params: {
          limit: 5,
          page: 1,
          teaching_studio_id: teaching_studio_id,
        },
      })
      .then((res) => {
        this.recommanderList = res.data.data.data;
      });
  },
};
</script>

<style lang="less" scoped>
.ReportDetail {
  padding-bottom: 10px;

  .ArrBox {
    margin: 0 auto;

    .title {
      // width: 232px;
      height: 48px;
      font-size: 14px;
      font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
      display: flex;
      align-items: center;

      p {
        span:nth-last-child {
          color: #ff8201;
        }

        .onL {
          cursor: pointer;

          &:hover {
            color: #3489ff;
          }
        }
      }
    }

    .discuss {
      .left {
        width: 1045px;
        border-radius: 6px;
        padding: 40px 30px;

        .send {
          font-size: 18px;
          font-weight: 600;
          color: #1a1a1a;
          padding-bottom: 20px;
        }

        //发布盒子
        .pushBox {
          width: 975px;
          height: 184px;
          border: 1px solid #ececec;
          border-radius: 4px;
          margin-bottom: 40px;

          //内发布盒子
          .outBox {
            width: 100%;
            background: #fff;
            position: relative;

            .inputText {
              /deep/ .el-textarea__inner {
                border: none;
              }
            }

            .addOneData {
              position: absolute;
              left: 865px;
              top: 134px;
              margin-bottom: 10px;
              width: 92px;
              height: 34px;
              line-height: 34px;
              background: #fa8c15;
              border-radius: 4px;
              font-size: 16px;
              font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
              font-weight: 700;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }

      .mainRight {
        width: 308px;
        min-height: 500px;

        .titleBox {
          border-radius: 8px 8px 0 0;
          height: 72px;
          align-items: center;
          padding: 0 25px;
          background: #ffffff;

          .titleLeft {
            p {
              font-size: 22px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #2a3037;
            }
          }
        }

        .mainBox {
          .ulBox {
            background: #ffffff;
            padding: 0 8px 8px 8px;
            border-radius: 0 0 8px 8px;

            .liBox {
              width: 292px;
              height: 134px;
              background: rgba(238, 246, 255, 1);
              border-radius: 8px;
              margin-bottom: 8px;
              padding: 16px;
              box-sizing: border-box;

              h4 {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #2a3037;
              }

              .rightTop {
                .imgBox {
                  width: 111px;
                  height: 62px;

                  img {
                    border-radius: 4px;
                  }
                }

                .txtbox {
                  width: 140px;

                  h4 {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #2a3037;
                  }

                  p {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: normal;
                    color: #666666;
                  }
                }
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
